<script lang="ts" setup>
const emit = defineEmits<{ (event: "handleClick"): void }>();
withDefaults(
	defineProps<{
		title?: string;
		tidTitle?: string;
		tidShow?: string;
		icon?: string;
		isAdminIcon?: boolean;
		isShowAll?: boolean;
	}>(),
	{ title: "" }
);

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const isSvg = (icon: string): boolean => icon.trim().startsWith("<svg");

const { t } = useT();
const { gameName, isExpanded } = useGameSearch();

const handleClickShowAll = () => {
	gameName.value = "";
	isExpanded.value = false;
	emit("handleClick");
};
</script>

<template>
	<div v-if="title || isShowAll" class="header">
		<AText
			v-if="title"
			class="title text-cannes"
			:size="{ full: 38, md: 24 }"
			:modifiers="['capitalize']"
			as="h3"
			:data-tid="tidTitle"
		>
			<template v-if="icon">
				<i v-if="isAdminIcon && isSvg(icon)" class="icon text-primary-400" v-html="icon" />
				<NuxtImg
					v-else-if="isAdminIcon"
					:src="`${baseImageUrl}${icon}`"
					width="32"
					height="32"
					loading="lazy"
					alt="category"
					class="icon"
				/>
				<ASvg v-else :name="icon" class="text-primary-400 icon" />
			</template>

			<span>{{ title }}</span>
		</AText>
		<AText
			v-if="isShowAll"
			:size="16"
			:modifiers="['underline']"
			class="show-all"
			:data-tid="tidShow"
			@click="handleClickShowAll"
		>
			{{ t("Show all") }}
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.icon,
.icon:deep(svg) {
	font-size: 32px;
	display: inline-flex;
	width: 32px;
	height: 32px;
	margin-left: 4px;

	@include media-breakpoint-down(md) {
		font-size: 26px;
		width: 26px;
		height: 26px;
	}
}

.show-all {
	color: var(--neutral-200);
	cursor: pointer;

	@include media-breakpoint-down(md) {
		font-size: 14px;
	}

	&:hover {
		text-decoration: none;
	}
}

.title {
	display: flex;
	align-items: center;
	gap: 8px;

	.icon {
		transform-origin: center;
		animation: swingAndScale 3s ease-in-out infinite;
	}
}

@keyframes swingAndScale {
	0%,
	100% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.4);
	}

	50% {
		transform: rotate(-10deg);
	}

	75% {
		transform: rotate(10deg);
	}
}
</style>
